import * as React from "react"
import Project from "../components/project"

const Projects = ({projects}) => (
  <div className="projects">
    <div id="hero"/>
    {projects.map((project, index) => { 
      return (
        <Project key={index} link={project.slug} image={project.image} title={project.title} />
      )
    })}
  </div>
)

export default Projects
