import React, { useEffect } from 'react';
import Layout from "../components/layout"
import Footer from "../components/footer"
import Projects from "../components/projects"
import {pageTransition} from '../utils/panelActions'
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import { graphql } from "gatsby"


const ProjectsPage = ({ data }) => {
  useEffect(() => {
    pageTransition();
  },[]);
  var projects = data.allDatoCmsTrkProject.nodes;
  return (
    <Layout>
      <Projects projects={projects} /> 
      <Spacer className='m-show'/>
      <Footer className='mt80' hideTop={true} />
    </Layout>
  )
}

export const Head = () => <Seo title="Projects" />

export default ProjectsPage

export const query = graphql`
  query ProjectPageQuery {
    allDatoCmsTrkProject {
      nodes {
        slug
        title
        image {
          gatsbyImageData
        }
      }
    }
  }
`
