import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { Link } from "gatsby"

const Project = ({ image, title, link }) => {
  const onClick = (e, title) => {
    e.preventDefault();
    if(window.innerWidth < 768) {
      gsap.to('main',{opacity:0, duration: .7});
    }
    var top = document.querySelector('.project--link[data-title="'+title+'"]').offsetTop;
    window.scrollTo({top: top, behavior: 'smooth', });
    document.querySelector('main').classList.add('fade-out');
    setTimeout(function(){ document.querySelector('.project-link[data-title="'+title+'"]').click() }, 700);
  } 
  return (
    <>
    <Link className='link project--link' to={'/project/'+link} data-title={title} data-link={link} onClick={(e) => onClick(e, title)}>
      <div className="feature--project bg-grey flex pos-rel overflow-hidden image--zoom">
        <div className="ma h1 light menu-hide pos-rel z-2">{title}</div>
        <GatsbyImage className="bg-image filter-br hero--image" image={image.gatsbyImageData}  alt="Hero Image" />
      </div>
    </Link>
    <Link className='project-link' data-title={title} to={'/project/'+link}></Link>
    </>
  )
}

export default Project
